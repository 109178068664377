// Generated by Framer (d894e1b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["JteXz4WJY"];

const serializationHash = "framer-Zs8jH"

const variantClassNames = {JteXz4WJY: "framer-v-c42qrd"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, title, width, ...props}) => { return {...props, i7NEtCqaR: title ?? props.i7NEtCqaR ?? "TESTIMONIALS"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, i7NEtCqaR, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "JteXz4WJY", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-Zs8jH", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-c42qrd", className)} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"JteXz4WJY"} ref={ref ?? ref1} style={{backgroundColor: "rgb(189, 255, 28)", borderBottomLeftRadius: 11, borderBottomRightRadius: 11, borderTopLeftRadius: 11, borderTopRightRadius: 11, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SUJNIFBsZXggTW9uby02MDA=", "--framer-font-family": "\"IBM Plex Mono\", monospace", "--framer-font-size": "12px", "--framer-font-weight": "600", "--framer-letter-spacing": "0.1em", "--framer-line-height": "110%", "--framer-text-alignment": "center"}}>TESTIMONIALS</motion.p></React.Fragment>} className={"framer-aojig7"} data-framer-name={"Text"} fonts={["GF;IBM Plex Mono-600"]} layoutDependency={layoutDependency} layoutId={"oT_HP2OZi"} style={{"--framer-paragraph-spacing": "0px"}} text={i7NEtCqaR} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-Zs8jH [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Zs8jH .framer-5osjwg { display: block; }", ".framer-Zs8jH .framer-c42qrd { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: flex-start; overflow: visible; padding: 8px 12px 8px 12px; position: relative; width: min-content; }", ".framer-Zs8jH .framer-aojig7 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Zs8jH .framer-c42qrd { gap: 0px; } .framer-Zs8jH .framer-c42qrd > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-Zs8jH .framer-c42qrd > :first-child { margin-left: 0px; } .framer-Zs8jH .framer-c42qrd > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 29
 * @framerIntrinsicWidth 125
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"i7NEtCqaR":"title"}
 * @framerImmutableVariables false
 */
const FramerYjXjiKdIG: React.ComponentType<Props> = withCSS(Component, css, "framer-Zs8jH") as typeof Component;
export default FramerYjXjiKdIG;

FramerYjXjiKdIG.displayName = "Section Badge";

FramerYjXjiKdIG.defaultProps = {height: 29, width: 125};

addPropertyControls(FramerYjXjiKdIG, {i7NEtCqaR: {defaultValue: "TESTIMONIALS", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerYjXjiKdIG, [{family: "IBM Plex Mono", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/YjXjiKdIG:default", url: "https://fonts.gstatic.com/s/ibmplexmono/v19/-F6qfjptAgt5VM-kVkqdyU8n3vAO8ldPg-IUDNg.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/ibmplexmono/v19/-F6qfjptAgt5VM-kVkqdyU8n3vAO8ldPg-IUDNg.ttf", weight: "600"}])